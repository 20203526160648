@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,700,800);

body {
  font-family: 'Open Sans';
  color: #3d4041;
}

h1 {
  font-weight: 800;
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
}

button.submit {
  font-size: 24px !important;
  padding: 15px 25px  !important;
  border-radius: 67px !important;
  background: #d24d53  !important;
  border-color: #d24d53  !important;
  font-size: 15px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
}

button.submit span {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

.full-width {
  width: 100%;
}

.center {
  text-align: center;
}

button:disabled,
button[disabled]{
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

a {
  color: #d24d53;
  text-decoration: none;
}
a.underlined {
  text-decoration: underline;
}

.logo {
  width: 150px;
  position: absolute;
  top: 24px;
  left: 24px;
}

@media (max-width: 768px) {
  .logo {
    left: 24px;
  }
}

.tc-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9999;
  width: 88px;
  height: 88px;
  margin: -44px 0 0 -44px;
  animation: spin 1s linear infinite;
  border: 5px solid #ECE0DE;
  border-top: 5px solid #D24D53;
  border-radius: 50%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.relative {
  position: relative;
}